<template>
  <div class="card">
    <div v-if="nameSubject != '' && nameSubject != undefined">
      <el-row>
        <el-col :span="22">
          <div
            @click="$router.push(`/subject/${subjectId}`)"
            class="
              color-00
              font-16
              mg-b-6
              cursor-pointer
              w-subject
              set-name-sub
            "
          >
            {{ nameSubject }} ({{ courseId }})
          </div>
        </el-col>
        <el-col :span="2">
          <div class="color-00 font-16 mg-b-6 text-right">
            <div class="favorite-img cursor-pointer">
              <div
                @click="
                  favorite == 1
                    ? delFavorite(subjectId)
                    : addFavorite(subjectId)
                "
              >
                <i
                  class="fas fa-star mg-r-7"
                  :class="
                    favorite == 1 ? 'color-star-light' : 'color-star-gray'
                  "
                ></i>
              </div>
              <router-link :to="`/subject/${subjectId}`">
                <i class="fas fa-chevron-right font-12 color-35"> </i>
              </router-link>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <router-link :to="`/subject/${subjectId}`">
      <el-progress
        :show-text="false"
        :percentage="percentNow > 100 ? 100 : percentNow"
        :stroke-width="15"
        :color="bgProgress == 'green' ? '#00D8A8' : '#00D8A8'"
      ></el-progress>
    </router-link>
    <router-link :to="`/subject/${subjectId}`">
      <div class="is-flex js-between">
        <p class="show-per" v-if="totalHour > 1">
          {{ nowHour + "/" + totalHour + "  Learning hours" }}
        </p>

        <p class="show-per" v-else>
          {{ nowHour + "/" + totalHour + "  Learning hour" }}
        </p>

        <p class="show-per">
          {{ percentNow + "/" + "100%" }}
        </p>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    nowHour: {
      type: Number,
      default: 0
    },
    totalHour: {
      type: Number,
      default: 0
    },
    percentNow: {
      type: Number,
      default: 0
    },
    bgProgress: {
      type: String,
      default: "green"
    },
    nameSubject: {
      type: String,
      default: ""
    },
    favorite: {
      type: Number,
      default: 0
    },
    subjectId: {
      type: String,
      default: ""
    },
    courseId: {
      type: String,
      default: ""
    }
  },
  methods: {
    addFavorite(id) {
      console.log("id", id);
      this.$emit("changeFavorite", id);
    },
    delFavorite(id) {
      console.log("id", id);
      this.$emit("changeFavorite", id);
    }
  }
};
</script>
