<template>
  <div class="circle">
    <el-progress
      type="circle"
      :percentage="percentage"
      :color="color"
      :stroke-width="strokewidth"
      :width="width"
    ></el-progress>
  </div>
</template>

<script>
export default {
  props: ["percentage", "color", "strokewidth", "width"],
};
</script>

<style></style>
