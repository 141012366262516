<template>
  <div id="all-page">
    <defaultTemplate>
      <div class="pd-x-5 pd-y-3">
        <p class="text-center font-18 mg-b-3 color-00 font-weight-500">
          DTM Learning hour
        </p>

        <!-- semester -->
        <!-- <div>
          <pre>
            {{ seMester }}
          </pre>
        </div> -->

        <div v-if="seMester">
          <div v-for="(semester, index) in seMester.semester" :key="index">
            <div class="pd-y-5 card">
              <router-link
                :to="`/learning/semester/${semester._id}`"
                class="cursor-pointer"
              >
                <div class="is-flex js-between border-b">
                  <p class="color-00 font-18 font-weight-500">
                    Semester {{ "Semester " + semester.nameSemester }}
                  </p>
                  <p class="el-icon-arrow-right"></p>
                </div>
                <el-row :gutter="10" class="pd-t-5">
                  <el-col :span="12" class="text-left mg-y-5">
                    <span class="font-40 text-dark font-weight-500">160 </span>
                    <span class="font-18 text-dark">hour</span>
                    <p class="font-12 text-dark">
                      {{ "Learing hour " + semester.hour + " hour" }}
                    </p>
                    <p class="font-12 text-dark">
                      Subject {{ semester.subject.length }}
                    </p>
                  </el-col>
                  <el-col :span="12" class="text-right">
                    <CirclePrecess
                      :percentage="
                        Number(((160 * 100) / semester.hour).toFixed(0))
                      "
                      :color="`#353535`"
                      :strokewidth="10"
                      :width="120"
                    />
                  </el-col>
                </el-row>
              </router-link>
            </div>
          </div>
        </div>

        <!-- <div v-if="data">
          <div
            class="pd-y-5 card"
            v-for="(semester, index) in data.user.semester"
            :key="'A' + index"
          >
            <router-link
              :to="`/learning/semester/${semester.id}`"
              class="cursor-pointer"
            >
              <div class="is-flex js-between border-b">
                <p class="color-00 font-18 font-weight-500">
                  Semester {{ semester.namesemester }}
                </p>
                <p class="el-icon-arrow-right"></p>
              </div>
              <el-row :gutter="10" class="pd-t-5">
                <el-col :span="12" class="text-left mg-y-5">
                  <span class="font-40 text-dark font-weight-500"
                    >{{ getHourSemester(semester) }}
                  </span>
                  <span class="font-18 text-dark">hour</span>
                  <p class="font-12 text-dark">
                    {{ "Learing hour : " + semester.hour + " hour" }}
                  </p>
                  <p class="font-12 text-dark">
                    {{ "Subject : " + semester.subject.length }}
                  </p>
                </el-col>
                <el-col :span="12" class="text-right">
                  <CirclePrecess
                    :percentage="
                      Number(
                        (
                          (getHourSemester(semester) * 100) /
                          semester.hour
                        ).toFixed(0)
                      )
                    "
                    :color="`#353535`"
                    :strokewidth="10"
                    :width="120"
                  />
                </el-col>
              </el-row>
            </router-link>
          </div>
        </div> -->
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import defaultTemplate from "@/template/default.vue";
import CardProgress from "@/components/CardProgress.vue";
import CirclePrecess from "@/components/circleProcess.vue";
import Data from "@/db/data.json";
import { HTTP } from "@/service/axios";
export default {
  mounted() {
    this.getSemester();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  components: {
    defaultTemplate,
    CardProgress,
    Data,
    CirclePrecess,
  },
  data() {
    return {
      data: Data,
      seMester: "",
    };
  },

  methods: {
    getSemester() {
      HTTP.defaults.headers.common.Authorization = `Bearer ` + this.user.token;
      HTTP.get(`user/learn`)
        .then((res) => {
          if (res.data.success) {
            this.seMester = res.data.obj[0];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getHourSemester(semester) {
      if (this.data != "") {
        let sumHour = 0;
        //console.log(semester, "semester semester semester");
        semester.subject.forEach((sj) => {
          sj.badges.forEach((bg) => {
            bg.history.forEach((his) => {
              sumHour = his.learnninghour + sumHour;
            });
          });
        });
        return sumHour;
      }
    },
  },
  // perCent(hour, hourTotal) {
  //   // console.log(hour);
  //   // console.log(hourTotal);
  //   //if (this.data != "") {
  //   //let percentNow = (hour * 100) / hourTotal;
  //   // console.log(percentNow, "dddd");
  //   // return Number(percentNow.toFixed(0));
  //   //}
  // },
};
</script>

<style></style>
