var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-container',{attrs:{"id":"nav-defalut"}},[_c('el-header',[_c('header',[_c('div',{staticClass:"text-ff"},[_c('el-row',{staticClass:"nav"},[_c('el-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/calendar"}},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require('@/assets/logo.png')}})])],1),_c('el-col',{staticClass:"is-flex ai-center js-end",attrs:{"span":12}},[_c('div',{staticClass:"cursor-pointer",on:{"click":_vm.openModal}},[_c('el-avatar',{attrs:{"size":25,"src":typeof _vm.user.profileImage == "undefined" ||
                    _vm.user.profileImage == null
                      ? "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                      : _vm.user.profileImage}})],1)])],1)],1)])]),_c('div',{staticClass:"position-relative"},[_c('el-main',{staticClass:"pd-0 min-height"},[_c('div',{staticClass:"over-flow-hieght"},[_vm._t("default")],2)])],1),_c('div',{attrs:{"id":"footer"}},[_c('span',{staticClass:"font-12 color-c4 font-weight-400"},[_vm._v("© Copyright Digital Talent Acedemy 2021")])]),_c('el-drawer',{attrs:{"visible":_vm.isCollapse,"show-close":false},on:{"update:visible":function($event){_vm.isCollapse=$event}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.isCollapse = false}}},[_c('i',{staticClass:"fas fa-chevron-right circle-right"})])]),_c('div',{staticClass:"box-normal"},[_c('div',{attrs:{"id":"box-profile"}},[_c('div',{staticClass:"circle-profile"},[_c('img',{staticClass:"logo",attrs:{"src":typeof _vm.user.profileImage == "undefined" ||
                _vm.user.profileImage == null
                  ? "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                  : _vm.user.profileImage}})]),_c('div',[_c('p',{staticClass:"set-name font-weight-500"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('p',{staticClass:"set-position"},[_vm._v(_vm._s(_vm.user.kyc.join(", ")))])])])]),_c('div',{staticClass:"box-page-list"},[_c('router-link',{class:_vm.$route.name == 'Profile' ? 'active' : '',attrs:{"to":"/profile"}},[_c('span',[_c('i',{staticClass:"fas fa-user"})]),_vm._v(" My Profile")]),_c('router-link',{class:_vm.$route.name == 'Calendar'
              ? 'active'
              : _vm.$route.name == 'createRoom'
              ? 'active'
              : _vm.$route.name == 'Event'
              ? 'active'
              : '',attrs:{"to":{ name: 'Event' }}},[_c('span',[_c('i',{staticClass:"fas fa-calendar-alt"})]),_vm._v(" Calendar")]),(_vm.checkType)?_c('router-link',{class:_vm.$route.name == 'Subject'
              ? 'active'
              : _vm.$route.name == 'SubjectDetail'
              ? 'active'
              : _vm.$route.name == 'SubjectBadgeDetail'
              ? 'active'
              : '',attrs:{"to":"/subject"}},[_c('span',[_c('i',{staticClass:"fas fa-tasks"})]),_vm._v("My Courses ")]):_vm._e(),_c('router-link',{class:_vm.$route.name == 'BadgesHistory'
              ? 'active'
              : _vm.$route.name == 'BadgeHistoryById'
              ? 'active'
              : _vm.$route.name == 'BadgeHistoryDetailById'
              ? 'active'
              : '',attrs:{"to":"/badges-history"}},[_c('span',[_c('i',{staticClass:"fas fa-file-alt"})]),_vm._v(" Badges History")])],1),_c('div',{staticClass:"box-page-list last",on:{"click":_vm.singOut}},[_c('router-link',{attrs:{"to":"/"}},[_c('span',[_c('i',{staticClass:"fas fa-sign-in-alt"})]),_vm._v("Log out")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }