<template>
  <div>
    <el-container id="nav-defalut">
      <el-header>
        <header>
          <div class="text-ff">
            <el-row class="nav">
              <el-col :span="12" class="">
                <router-link to="/calendar">
                  <img
                    :src="require('@/assets/logo.png')"
                    class="cursor-pointer"
                  />
                </router-link>
              </el-col>
              <el-col :span="12" class="is-flex ai-center js-end">
                <!-- <router-link to="/calendar">
                  <i class="fas fa-bell mg-r-7 text-white"></i>
                </router-link> -->

                <!-- <i
                  class="fas fa-user-circle cursor-pointer"
                 @click="openModal"
                ></i> -->
                <div @click="openModal" class="cursor-pointer">
                  <!-- <Avatar 
                  :size="25"
                    :src="
                      typeof user.profileImage == `undefined` ||
                      user.profileImage == null
                        ? `https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png`
                        : user.profileImage
                    "
                  ></Avatar> -->
                  <el-avatar
                    :size="25"
                    :src="
                      typeof user.profileImage == `undefined` ||
                      user.profileImage == null
                        ? `https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png`
                        : user.profileImage
                    "
                  ></el-avatar>
                </div>
                <!-- <img
                @click="openModal"
                :src="require('@/assets/user.png')"
                class="logo cursor-pointer"
              /> -->
              </el-col>
            </el-row>
          </div>
        </header>
      </el-header>
      <div class="position-relative">
        <el-main class="pd-0 min-height">
          <div class="over-flow-hieght">
            <slot />
          </div>
        </el-main>
      </div>
      <div id="footer">
        <span class="font-12 color-c4 font-weight-400"
          >© Copyright Digital Talent Acedemy 2021</span
        >
      </div>
      <el-drawer :visible.sync="isCollapse" :show-close="false">
        <template slot="title">
          <div class="cursor-pointer" @click="isCollapse = false">
            <i class="fas fa-chevron-right circle-right"></i>
          </div>
        </template>
        <div class="box-normal">
          <div id="box-profile">
            <div class="circle-profile">
              <img
                :src="
                  typeof user.profileImage == `undefined` ||
                  user.profileImage == null
                    ? `https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png`
                    : user.profileImage
                "
                class="logo"
              />
            </div>
            <div>
              <p class="set-name font-weight-500">
                {{ user.firstName }} {{ user.lastName }}
              </p>
              <p class="set-position">{{ user.kyc.join(", ") }}</p>
            </div>
          </div>
        </div>
        <div class="box-page-list">
          <router-link
            to="/profile"
            :class="$route.name == 'Profile' ? 'active' : ''"
            ><span><i class="fas fa-user"></i> </span> My Profile</router-link
          >
          <router-link
            :to="{ name: 'Event' }"
            :class="
              $route.name == 'Calendar'
                ? 'active'
                : $route.name == 'createRoom'
                ? 'active'
                : $route.name == 'Event'
                ? 'active'
                : ''
            "
          >
            <span><i class="fas fa-calendar-alt"></i> </span>
            Calendar</router-link
          >
          <router-link
            v-if="checkType"
            to="/subject"
            :class="
              $route.name == 'Subject'
                ? 'active'
                : $route.name == 'SubjectDetail'
                ? 'active'
                : $route.name == 'SubjectBadgeDetail'
                ? 'active'
                : ''
            "
            ><span><i class="fas fa-tasks"></i> </span>My Courses
          </router-link>
          <router-link
            to="/badges-history"
            :class="
              $route.name == 'BadgesHistory'
                ? 'active'
                : $route.name == 'BadgeHistoryById'
                ? 'active'
                : $route.name == 'BadgeHistoryDetailById'
                ? 'active'
                : ''
            "
          >
            <span>
              <i class="fas fa-file-alt"></i>
            </span>
            Badges History</router-link
          >
        </div>
        <div class="box-page-list last" @click="singOut">
          <router-link to="/"
            ><span><i class="fas fa-sign-in-alt"></i></span>Log out</router-link
          >
        </div>
      </el-drawer>
    </el-container>
  </div>
</template>
<script>
import { HTTPDtm } from "@/service/axios";
import Avatar from "vue-avatar";
export default {
  components: {
    Avatar
  },
  computed: {
    checkType() {
      let check = this.user.kyc.findIndex(a => a == "DTA Reskill");
      return check > -1 && this.user.kyc.length == 1 ? false : true;
    },
    user() {
      let data = this.$store.state.user;
      //data.kyc = ["DTA Reskill"];
      return data;
    }
  },
  mounted() {
    //this.getProfile();
  },
  data() {
    return {
      isCollapse: false,
      getProfiles: null
    };
  },
  methods: {
    getProfile() {
      // HTTP.defaults.headers.common.Authorization =
      //   `Bearer ` + this.user.accessToken;
      // HTTP.get(`profile`)
      //   .then(res => {
      //     if (res.data.success) {
      //       this.getProfiles = res.data.obj;
      //     }
      //   })
      //   .catch(e => {
      //     console.log("Oops!");
      //     console.log(e);
      //     window.location.href = process.env.VUE_APP_BASE_URL;
      //     //this.alertCatchError(e.response.status);
      //   });

      /* from DTM API */
      HTTPDtm.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTPDtm.get(`profile`)
        .then(res => {
          this.getProfiles = res.data;
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        })
        .finally(() => {});
    },
    openModal() {
      this.isCollapse = !this.isCollapse;
    },
    async singOut() {
      await this.$store.dispatch("user/logout");
      await window.localStorage.removeItem("DTA");
      // window.location =
      //   "https://dtm.avalue.co.th/login/guest?action=logout&redirect=" +
      //   process.env.VUE_APP_BASE_URL;
      window.location = process.env.VUE_APP_BASE_URL;
      // this.$liff.openWindow({
      //   url: "https://dtm.avalue.co.th/login/guest?action=logout&redirect=" + process.env.VUE_APP_BASE_URL
      // })
    }
  }
};
</script>
